import { mediaQueries, style } from '@do/walrus';
import _get from 'lodash/get';
import Link from 'next/link';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  getAppId,
  getCategory,
  getCreated,
  getIcon,
  getName,
  getSafeName,
  getStatus,
  getSummary,
  getType
} from '../../selectors';
import { PageHeader } from '../../styles/components';
import { isSaas, isSls } from '../../utils';
import { CATEGORY_GRID_BREAKPOINTS } from '../../utils/constants';
import getCreateButtonUrl from '../../utils/getCreateButtonUrl';
import OneClickCard from '../OneClickResource/OneClickCard';
import { ONE_CLICK_DIMENSIONS } from '../OneClickResource/constants';

const ThreeCards = 'min-width: 896px';

interface Props {
  headerText?: string;
  apps: any;
  leftAlign?: boolean;
  mobileLeftAlign?: boolean;
  largeHeader?: boolean;
}

interface GridContainerProps {
  leftAlign: boolean;
  mobileLeftAlign: boolean;
}

const AppListingHeader = styled.h2`
  font-size: 1.5rem;
  font-weight: ${style.vars.fontWeight.bold};
  margin: 0 auto;
  padding-top: ${style.vars.space['4']};
  grid-column: 1 / -1;
  width: 100%;
`;

const AppListingPageHeader = styled(PageHeader)`
  grid-column: 1 / -1;
  width: 100%;
`;

const GridContainer = styled.div<GridContainerProps>`
  margin-top: ${style.vars.space['3']};
  display: grid;
  gap: ${parseInt(ONE_CLICK_DIMENSIONS.MARGIN, 10) * 2}px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, ${ONE_CLICK_DIMENSIONS.WIDTH});

  @media (${ThreeCards}) {
    column-gap: ${parseInt(ONE_CLICK_DIMENSIONS.MARGIN, 10)}px;
  }
  ${mediaQueries.xlargeMin} {
    gap: ${parseInt(ONE_CLICK_DIMENSIONS.MARGIN, 10) * 2}px;
  }

  ${({ leftAlign }) =>
    leftAlign &&
    css`
      @media (${CATEGORY_GRID_BREAKPOINTS.ThreeCards}) {
        justify-content: flex-start;
      }
    `}

  ${({ mobileLeftAlign }) =>
    mobileLeftAlign &&
    css`
      justify-content: flex-start;
    `}
`;

const OneClickWrapper = styled.div``;

const FilteredAppListingGrid: React.FC<Props> = ({
  headerText,
  apps,
  leftAlign,
  mobileLeftAlign,
  largeHeader
}) => {
  // only display approved apps
  apps =
    apps?.filter((app) => {
      return getStatus(app) === 'approved';
    }) ?? [];

  if (!apps.length) {
    return null;
  }

  return (
    <>
      <GridContainer leftAlign={leftAlign} mobileLeftAlign={mobileLeftAlign}>
        {headerText && (
          <>
            {largeHeader ? (
              <AppListingPageHeader className="do-u-pt--remove do-u-pb--remove do-u-mb--remove">
                {headerText}
              </AppListingPageHeader>
            ) : (
              <AppListingHeader>{headerText}</AppListingHeader>
            )}
          </>
        )}
        {apps.map((app, index) => {
          const createButtonUrl = getCreateButtonUrl(app);
          const safeName = getSafeName(app) ?? _get(app, 'safeName[0]');
          const appId = getAppId(app) ?? _get(app, 'appId');
          const href =
            isSaas(app) || isSls(app)
              ? '/add-ons/[safeName]'
              : '/apps/[safeName]';
          const hrefAs =
            isSaas(app) || isSls(app)
              ? `/add-ons/${safeName}`
              : `/apps/${safeName}`;

          return (
            <OneClickWrapper key={`OneClick-${appId}-${index}`}>
              <Link
                href={href}
                as={hrefAs}
                prefetch={false}
                passHref={true} // Forces Link to send the href property to its child.
              >
                <OneClickCard
                  key={`OneClickCard-${appId}-${index}`}
                  icon={getIcon(app) ?? _get(app, 'customData.icon')}
                  name={getName(app)}
                  appID={getAppId(app)}
                  category={getCategory(app)}
                  created={getCreated(app)}
                  summary={getSummary(app) ?? _get(app, 'customData.summary')}
                  createButtonUrl={createButtonUrl}
                  type={getType(app)}
                  slug={safeName}
                />
              </Link>
            </OneClickWrapper>
          );
        })}
      </GridContainer>
    </>
  );
};

export default FilteredAppListingGrid;
