const copy = {
  breadcrumbsLabel: 'Breadcrumbs',
  create: 'Create',
  createDroplet: 'Create Droplet',
  createGPUDroplet: 'Deploy to GPU Droplet',
  createAddon: `Install Add-On`,
  digitalOceanMarketplace: 'DigitalOcean Marketplace',
  droplet: 'Droplet',
  installApp: 'Install App',
  kubernetes: 'Kubernetes',
  licensedSoftware: 'Licensed Software',
  new: 'New',
  seeDetails: 'See details',
  getStarted: 'Get Started',
  signUpFor: 'Get Started with',
  sitemap: 'Sitemap',
  sitemapTitle: 'Sitemap | DigitalOcean Marketplace',
  allApps: 'All Apps',
  staffPick: 'Staff Pick',
  yes: 'Yes',
  no: 'No',
  none: 'None',
  /* eslint-disable-next-line @do/no-hardcoded-prices */
  getFreeTrialCta: 'Get a $100 Free Trial',
  postMessageAppListener: {
    title: 'DigitalOcean Iframe'
  },
  nodeMongoBanner: {
    new: 'New! DigitalOcean Managed MongoDB with NodeJS 1-Click App',
    description:
      "A fully managed database as a service for modern apps. We'll setup the connection between your NodeJS 1-Click App and the managed database.",
    tryIt: 'Try it',
    learnMore: 'Learn more'
  },
  appScriptsSection: {
    upgradeInstructions: 'Upgrade instructions',
    downloadUpgradeInstructions: 'Download upgrade instructions',
    upgradeAppName: (appName: string) =>
      `You can run the following command to update ${appName} to the latest version.`,
    uninstallInstructions: 'Uninstall instructions',
    downloadUninstallInstructions: 'Download uninstall instructions',
    uninstallAppName: (appName: string) =>
      `You can run the following command to uninstall ${appName}.`
  },
  plansSelector: {
    free: 'Free',
    pricePerMonth: (amount: number) => `$${amount}/mo`
  },
  vendorPortalForm: {
    pendingBannerTitle: 'Listing cannot be updated when it is in review.',
    pendingBannerDescription:
      'Please contact one-clicks-team@digitalocean.com for any changes.',
    appNameTrailingSpacesError: 'App name cannot start or end with spaces.',
    descriptionAllSpacesError: 'Description cannot contain only spaces.'
  },
  vendorPortal: {
    name: 'Name',
    size: 'Size',
    created: 'Created',
    appStatus: {
      inReview: 'In review',
      checkingImage: 'Checking image',
      approved: 'Approved',
      suspended: 'Suspended',
      rejected: 'Rejected',
      imageCheckFailed: 'Rejected, image check failed'
    },
    errorLoadingSnapshots: 'There was an error loading your snapshots.',
    loadingSnapshots: 'Loading snapshots...',
    updateCopyDisclaimer:
      'Your app will be updated in a few minutes after you submit the copy update.',
    updateSnapshotDisclaimer:
      "Looks like you want to update the system image. We will review it typically within 2-3 business days and get back to you soon. Once the app image is in review, you won't be able to update the image.",
    snapshotsOwnershipAck:
      'I acknowledge that the selected snapshot will no longer be available once the listing is submitted.',
    snapshotInstructions:
      "You need a Snapshot in order to create a 1-Click App, and you don't have any associated with your DigitalOcean account. Please click the button below to learn how to create a Snapshot that's ready for submission to the DigitalOcean Marketplace.",
    viewInstructions: 'View instructions',
    didntSeeYourSnapshot: "Didn't see your snapshot?",
    ifYouDontSeeASnapshot:
      "If you don't see a snapshot that you are looking for, it might be because you inadvertently created the snapshot in another account. You can migrate it to your Team account that is associated with Vendor Portal.",
    thereAreNoSnapshotsAssociatedPart1:
      'There are no Snapshots associated with this account. You should create one in ',
    thereAreNoSnapshotsAssociatedPart2: 'DigitalOcean Cloud Control Panel',
    thereAreNoSnapshotsAssociatedPart3: ' first or ',
    thereAreNoSnapshotsAssociatedPart4: 'migrate one from another account',
    thereAreNoSnapshotsAssociatedPart5:
      " if you've inadvertently created one there."
  },
  appDetails: {
    softwareIncludedTooltip: `Didn't find the versions you need? Don't worry, you can update the software after you create a Droplet.`,
    experimentCTAButtonCopy: 'Try it now',
    descriptionTitle: 'Description',
    gettingStartedTitle: 'Getting Started'
  },
  additionalLinks: {
    additionalLinksTitle: 'Additional Links',
    digitalOceanLinksTitle: 'More DigitalOcean Links',
    digitalOceanCommunity: 'DigitalOcean Community',
    developersSupportingDevelopers: 'Developers supporting developers',
    askAQuestion: 'Ask a Question',
    askAnythingRelated:
      'Ask anything related to system administration, programming, devops, open source, or the DigitalOcean platform.',
    listYourOneClick: 'List your 1-Click App in the DigitalOcean Marketplace',
    listYourSolution: 'List your solution in the DigitalOcean Marketplace',
    partnerWithUs:
      'Partner with us to build traction for your open source project or software company.'
  },
  addonsAppDetail: {
    metaTitle: (addOnName) => `${addOnName} | DigitalOcean Marketplace Add-Ons`,
    metaDescription:
      'DigitalOcean Marketplace lets developers easily discover and quickly launch services, components and tools from the open source projects and companies that they love.',
    imageAlt: 'DigitalOcean',
    addon: 'Add-On',
    addons: 'Add-Ons',
    description: 'Description',
    plans: 'Plans',
    included: 'Included',
    notIncluded: 'Not Included',
    supportDetails: {
      label: 'Support Details',
      name: 'Supported By',
      hours: 'Hours',
      url: 'Support URL',
      email: 'Support Email'
    },
    summaryAndDescription: 'Add-On Summary & Description',
    summary: 'Summary',
    additionalLinks: 'Additional Links',
    digitalOceanLinksTitle: 'More DigitalOcean Links',
    overview: 'Overview',
    security: 'Security',
    securityAndCompliance: 'Security & Compliance',
    generalInformation: 'General Information',
    privacyAndDataGovernance: 'Privacy & Data Governance',
    certificationsAndCompliance: 'Certifications & Compliance',
    termsOfService: 'Terms of Service',
    endUserLicensingAgreement: 'End User Licensing Agreement',
    dataRetentionPolicy: 'Data Retention Policy',
    dataArchivingAndRemovalPolicy: 'Data Archiving and Removal Policy',
    dataStoragePolicy: 'Data Storage Policy',
    dataCenterLocations: 'Data center locations',
    dataHostingDetails: 'Data hosting details',
    subprocessorsPolicy: 'Sub-processors Policy',
    hasADedicatedSecurityTeam: 'Has a dedicated security team',
    hasADisclosureProgram: 'Has a vulnerability disclosure program',
    hasABugBounty: 'Has a bug bounty program',
    isReviewedUnderVulnerabilityProgram:
      'App/service is reviewed under vulnerability management program',
    requiresThirdParty: 'Requires third-party authorizations or connections',
    hasDedicatedContactForSecurity:
      'Has dedicated contact information for security issues',
    logoAltText: (appName: string) => `Logo for ${appName}`,
    screenshotAltText: (appName: string) => `Screenshot for ${appName}`,
    byVendor: (vendorName: string) => `by ${vendorName}`,
    addApp: (appName: string) => `Add ${appName}`
  },
  addOnsAccordion: {
    appOverview: 'Add-on overview',
    plans: 'Plans',
    security: 'Security',
    previewImages: 'Preview images',
    contactSupport: 'Contact support',
    additionalLinks: 'Additional links',
    digitalOceanLinksTitle: 'More DigitalOcean Links'
  },
  breadCrumbs: {
    allCategories: 'All Categories'
  },
  gettingStarted: {
    quickAccessTo: (appName: string) => `Quick access to ${appName}`,
    afterDeploying: (appName: string) =>
      `Getting started after deploying ${appName}`,
    afterAccountCreation: 'Getting started after account creation'
  },
  search: {
    placeholder: 'Search for other solutions'
  },
  detailAccordion: {
    appOverview: 'App overview',
    getStartedInstructions: 'Get started instructions',
    digitalOceanCommunityTutorials: 'DigitalOcean community tutorials',
    contactSupport: 'Contact support',
    additionalLinks: 'Additional links'
  },
  tutorials: {
    featuredTutorial: 'Featured Tutorial',
    seeOurTutorials: (name: string) => `See our tutorials on ${name}`,
    seeAllTutorials: (name: string) => `See all ${name} tutorials`
  },
  navigation: {
    categories: 'Categories',
    useCases: 'Use Cases',
    forVendors: 'For Vendors',
    switchTo: 'Switch To',
    becomeVendorTitle: 'Become a Vendor',
    becomeVendorDescription:
      'Get your solution onto the DigitalOcean Marketplace',
    gettingStartedTitle: 'Vendor Guidelines and Resources',
    gettingStartedDescription:
      'Information for Vendors and prospective Vendors',
    vendorForumTitle: 'Vendor Forum',
    vendorPortalTitle: 'Vendor Portal',
    vendorPortalDescription: 'Manage your DigitalOcean Marketplace solutions',
    controlPanelTitle: 'Control Panel',
    docsTitle: 'Docs',
    supportTitle: 'Support',
    saasApiDocumentation: 'SaaS Vendor API Documentation',
    saasTax: 'SaaS Vendor Tax Information'
  },
  socialLinks: {
    twitter: 'DigitalOcean on Twitter',
    facebook: 'DigitalOcean on Facebook',
    instagram: 'DigitalOcean on Instagram',
    youtube: 'DigitalOcean on YouTube',
    linkedin: 'DigitalOcean on LinkedIn',
    glassdoor: 'DigitalOcean on Glassdoor'
  }
};

export default copy;
