import getConfig from 'next/config';
import copy from '../components/copy';
import supportIcon from '../components/icons/support.svg?sprite';
import { NavigationItem } from '../types';

const { publicRuntimeConfig } = getConfig();

export const CONTROL_PANEL_URL =
  publicRuntimeConfig.CLOUD_URL || 'https://cloud.digitalocean.com';

export const DOCS_URL = 'https://digitalocean.com/docs';
export const RESTORE_DROPLET_DOCS_URL =
  'https://docs.digitalocean.com/products/images/snapshots/how-to/create-and-restore-droplets/#create-new-droplets-from-a-snapshot';
export const CHANGE_OWNERS_DOCS_URL =
  'https://docs.digitalocean.com/products/images/snapshots/how-to/change-owners/';
export const SUPPORT_URL = 'https://www.digitalocean.com/support/start';
export const VENDORS_URL = 'https://marketplace.digitalocean.com/vendors';
export const VENDOR_PORTAL_URL = `${CONTROL_PANEL_URL}/vendorportal`;
export const GETTING_STARTED_URL =
  'https://marketplace.digitalocean.com/vendors/getting-started-as-a-digitalocean-marketplace-vendor';

export const MARKETPLACE_SITEMAP_URL =
  'https://marketplace.digitalocean.com/sitemap';
export const MARKETPLACE_IDEAS_URL =
  'https://ideas.digitalocean.com/marketplace';

export const MARKETPLACE_PARTNERS_URL =
  'https://github.com/digitalocean/marketplace-partners';
export const IMAGE_CHECK_SCRIPT_URL = `${MARKETPLACE_PARTNERS_URL}/blob/master/scripts/img_check.sh`;

export const BASE_SAAS_URL = `${CONTROL_PANEL_URL}/add-ons`;
export const BASE_SAAS_CREATE_URL = `${CONTROL_PANEL_URL}/add-ons/create`;

export const VENDOR_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    link: VENDORS_URL,
    title: copy.navigation.becomeVendorTitle,
    subtitle: copy.navigation.becomeVendorDescription
  },
  {
    link: GETTING_STARTED_URL,
    title: copy.navigation.gettingStartedTitle,
    subtitle: copy.navigation.gettingStartedDescription
  },
  {
    link: VENDOR_PORTAL_URL,
    title: copy.navigation.vendorPortalTitle,
    subtitle: copy.navigation.vendorPortalDescription
  }
];

export const SWITCH_TO_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    link: CONTROL_PANEL_URL,
    title: copy.navigation.controlPanelTitle,
    icon: 'product-control-panel'
  },
  {
    link: DOCS_URL,
    title: copy.navigation.docsTitle,
    icon: 'overview-docs'
  },
  {
    link: SUPPORT_URL,
    title: copy.navigation.supportTitle,
    icon: supportIcon.id,
    iconXlinkHrefPrefix: ''
  },
  {
    link: VENDOR_PORTAL_URL,
    title: copy.navigation.vendorPortalTitle,
    icon: 'product-vendor-portal'
  }
];

export const DEFAULT_SIDEBAR_LINKS = [
  {
    url: 'https://www.digitalocean.com/community',
    title: copy.additionalLinks.digitalOceanCommunity,
    description: copy.additionalLinks.developersSupportingDevelopers
  },
  {
    url: 'https://www.digitalocean.com/community/questions/new',
    title: copy.additionalLinks.askAQuestion,
    description: copy.additionalLinks.askAnythingRelated
  },
  {
    url: 'https://marketplace.digitalocean.com/vendors',
    title: copy.additionalLinks.listYourSolution,
    description: copy.additionalLinks.partnerWithUs
  }
];

// Algolia credentials: https://github.internal.digitalocean.com/digitalocean/algolia-universal-search#algolia-credentials
export const ALGOLIA_APP_ID = '6ZHEUVKJ88';
export const ALGOLIA_PUBLIC_KEY = 'c5470567eae7fa1177d43222e18ba086';

export const DATE_FORMAT = 'MM/DD/YY';
export const VENDOR_PORTAL_USAGE_DATE_FORMAT = 'MM/DD/YYYY';

export const CATEGORY_GRID_BREAKPOINTS = {
  TwoCards: 'min-width: 664px',
  ThreeCards: 'min-width: 1068px',
  FourCards: 'min-width: 1372px',
  FiveCards: 'min-width: 1676px'
};

export const SUPPORTED_VENDOR_PORTAL_APP_TYPES = ['droplet', 'kubernetes'];

export const SIDENAV_WIDTH = 250;
export const ALL_KEY = 'All';
export const BLOGS_FORUMS_KEY = 'Blogs and Forums';
export const ECOMMERCE_KEY = 'eCommerce';
export const ADDONS_KEY = 'Add-Ons';

export const APP_NAME_MIN_LENGTH = 3;

export const ALL_SPACES_REGEX = /^(?!\s+$).*/;

export const ANIMATION_TRANSITION_DURATION_MS = 1000;
export const ACTION_MENU_ANIMATION_TRANSITION_DURATION_MS = 250;

export const FAKE_AJS_ANONYMOUS_ID = '%22fake-anonymous-id%22';
export const FAKE_AJS_USER_ID = '%22fake-user-id%22';

export const UTM_SOURCE_PARAM =
  'utm_source=digitalocean_marketplace_app_listing_page';

// Optimizely experiments
export const PERSONALIZED_CTA_EXPERIMENT_NAME =
  'mp-4204__free_trial_copy_on_cta';
export enum PERSONALIZED_CTA_EXPERIMENT_VARIANTS {
  CONTROL = 'control',
  TREATMENT = 'treatment'
}

// Analytics/tracking events
export const MP_CATALOG_CREATE_ADDON_EVENT_PREFIX = 'mp_catalog_create_addon';
export const MP_CATALOG_CREATE_K8S_EVENT_PREFIX = 'mp_catalog_create_k8s';
export const MP_CATALOG_CREATE_DROPLET_EVENT_PREFIX =
  'mp_catalog_create_droplet';
export const MP_CATALOG_CREATE_SAAS_AFFILIATE_EVENT_PREFIX =
  'mp_catalog_create_saas_affiliate';

export const ALGOLIA_TRACK_VIEW_EVENT = 'marketplace_product_viewed';
export const ALGOLIA_TRACK_CLICK_EVENT = 'marketplace_product_clicked';
export const ALGOLIA_TRACK_ADD_EVENT = 'marketplace_product_added';
