import { style } from '@do/walrus';
import React from 'react';
import styled from 'styled-components';
import OneClickResource from './OneClickResource';
import { ONE_CLICK_DIMENSIONS } from './constants';

const OneClickCardContainer = styled.div`
  width: ${ONE_CLICK_DIMENSIONS.WIDTH};
  height: ${ONE_CLICK_DIMENSIONS.HEIGHT};
  border-radius: ${style.vars.borderRadius.base};
  box-shadow: 0 2px 4px 0 ${style.colors.shadesOfGrey['3']};
  border: 1px solid ${style.colors.shadesOfGrey['3']};
  display: inline-block;
  position: relative;
  margin: 0 auto;
  background-color: white;
  cursor: pointer;
`;

interface Props {
  icon: string;
  name: string;
  appID: string;
  category?: string;
  created?: number;
  summary?: string;
  createButtonUrl?: string;
  type?: string;
  href?: string;
  slug?: string;
}

const OneClickCard = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      icon,
      name,
      appID,
      category,
      created,
      summary,
      createButtonUrl,
      type,
      href,
      slug
    },
    ref
  ) => (
    <OneClickCardContainer ref={ref}>
      <OneClickResource
        name={name}
        icon={icon}
        appID={appID}
        category={category}
        created={created}
        summary={summary}
        createButtonUrl={createButtonUrl}
        type={type}
        href={href}
        slug={slug}
      />
    </OneClickCardContainer>
  )
);

export default OneClickCard;
