import { useQuery } from '@do/seashell/graphql';
import { Button, mediaQueries, style } from '@do/walrus';
import _get from 'lodash/get';
import React from 'react';
import styled, { css } from 'styled-components';
import GetAddonsByApp from '../../graphql/queries/getAddonsByApp.gql';
import { AppType } from '../../interfaces/OpenChannel';
import { TruncatedText } from '../../styles/components';
import { isKubernetes } from '../../utils/appTypeHelpers';
import Image from '../Image';
import Pill, { PillElt } from '../Pill';
import copy from '../copy';
import { ONE_CLICK_DIMENSIONS, ONE_CLICK_ICON_DIMENSIONS } from './constants';

const MIN_SUMMARY_HEIGHT = '42px';
const ANIMATION_DURATION = '250ms';
const PENDO_ID = 'create-1-click-app-card-button';

interface HorizontalProps {
  isHorizontal: boolean;
}

interface WrapperProps {
  isHorizontal: boolean;
}

const CardActionsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;

  transition: all ${ANIMATION_DURATION} ease-in-out;
`;

const SeeDetailsCardButton = styled(Button)`
  background: ${style.colors.shadesOfGrey['1']};
  margin-bottom: ${style.vars.space['2']};
`;

const OneClickCardImageContainer = styled.div<HorizontalProps>`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: ${ONE_CLICK_ICON_DIMENSIONS.MAX_WIDTH};
  height: ${ONE_CLICK_ICON_DIMENSIONS.MAX_HEIGHT};
  transition: all ${ANIMATION_DURATION} ease-in-out;

  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      display: inline-flex;
      width: ${ONE_CLICK_ICON_DIMENSIONS.HORIZONTAL_MAX_WIDTH};
      height: ${ONE_CLICK_ICON_DIMENSIONS.HORIZONTAL_MAX_HEIGHT};
      background-color: ${style.colors.shadesOfGrey['1']};
      border-radius: ${style.vars.borderRadius.base};
      border: 1px solid ${style.colors.shadesOfGrey['4']};
      margin-right: 20px;
      margin-bottom: ${style.vars.space['3']};
    `}
`;

const OneClickResourceCopy = styled.div<HorizontalProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all ${ANIMATION_DURATION} ease-in-out;

  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      position: static;

      ${mediaQueries.mediumMin} {
        width: 100%;
        display: block;
      }
    `}
`;

const OneClickResourceName = styled(TruncatedText)<HorizontalProps>`
  font-size: 28px;
  font-weight: ${style.vars.fontWeight.bold};
  color: ${style.colors.primary.base};
  margin-bottom: 5px;
  line-height: 1.25;
  word-break: break-word;

  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      font-size: 40px;
      color: black;
      font-weight: ${style.vars.fontWeight.light};

      ${mediaQueries.mediumMin} {
        font-size: 36px;
      }

      ${mediaQueries.largeMin} {
        font-size: 40px;
      }
    `}
`;

const OneClickDeveloper = styled.div`
  color: ${style.colors.shadesOfGrey['8']};
`;

const OneClickResourceMeta = styled.div<HorizontalProps>`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  ${({ isHorizontal }) =>
    isHorizontal &&
    css`
      margin-top: ${style.vars.space['1']};

      ${mediaQueries.mediumMin} {
        p {
          display: inline-block;
          margin-right: 20px;
          margin-top: 0;
        }
      }
    `}
`;

const Summary = styled.div`
  width: calc(100% - ${style.vars.space['4']});
  min-height: ${MIN_SUMMARY_HEIGHT};

  p {
    margin-bottom: 0;
    font-size: ${style.vars.fontSize.small};
    color: ${style.colors.grey.dark};
  }
`;

const MetaDetails = styled.div`
  margin-bottom: ${style.vars.space['2']};
  overflow: hidden;

  strong {
    margin-right: ${style.vars.space['1']};
  }

  p {
    color: ${style.colors.grey.darkest};
    /* do not remove background-color - a11y purposes */
    background-color: ${style.colors.white};
    min-height: ${style.vars.space['4']};
    min-width: calc(${ONE_CLICK_DIMENSIONS.WIDTH} - ${style.vars.space['4']});
    margin: 0;
  }
`;

const HorizontalMetaDetails = styled(MetaDetails)`
  width: 100%;
  margin-bottom: ${style.vars.space['2']};

  p {
    min-height: initial;
    min-width: initial;
    display: inline-block;
    &:not(:empty) {
      margin-right: ${style.vars.space['3']};
    }
  }
`;

const OneClickResourceContainer = styled.div<HorizontalProps>`
  position: relative;
  height: 100%;

  display: flex;

  ${mediaQueries.smallMax} {
    flex-wrap: wrap;
  }

  ${PillElt} {
    margin: 0;

    ${({ isHorizontal }) =>
      !isHorizontal &&
      css`
        position: absolute;
        top: 0;
        right: 0;
      `}
  }
`;

const OneClickResourcePaddingWrapper = styled.div<WrapperProps>`
  /* Only show hover if not on mobile, otherwise clicking the card will take you to the app detail page like normal */
  ${mediaQueries.mediumMin} {
    ${({ isHorizontal }) =>
      !isHorizontal &&
      css`
        &:hover {
          ${CardActionsWrapper} {
            visibility: visible;
            opacity: 1;
          }

          ${OneClickResourceCopy} {
            transform: translateY(-50px);
          }

          ${OneClickCardImageContainer} {
            visibility: hidden;
            opacity: 0;
          }
        }
      `};
  }

  ${({ isHorizontal }) =>
    !isHorizontal &&
    css`
      padding: ${style.vars.space['4']};
      height: 100%;
    `}
`;

const DetailPill = styled(Pill)`
  && {
    display: flex;
    align-items: center;
    margin-right: ${style.vars.space['3']};
    background-color: ${style.colors.grey.dark};
  }
`;

const NewDetailPill = styled(DetailPill)`
  && {
    background-color: ${style.colors.primary.base};
  }
`;

const getCreateButtonText = {
  [AppType.KUBERNETES]: copy.installApp,
  [AppType.DROPLET]: copy.createDroplet,
  [AppType.SAAS]: copy.createAddon,
  [AppType.SAAS_AFFILIATE]: copy.getStarted,
  [AppType.SLS]: copy.createAddon,
  [AppType.AI_MODEL]: copy.createGPUDroplet
};

const APP_TYPE_TEXT = {
  [AppType.KUBERNETES]: 'Kubernetes 1-Click',
  [AppType.DROPLET]: 'Droplet 1-Click',
  [AppType.SAAS]: 'Add-Ons',
  [AppType.SLS]: 'License Add-on',
  [AppType.AI_MODEL]: 'AI Model'
};

const getAppTypeText = (type: string, slug: string) => {
  if (slug === 'elk-blueprint') {
    return 'Blueprint';
  }
  return APP_TYPE_TEXT[type];
};

function isNewByDate(created: number) {
  if (!created) {
    return false;
  }

  const today = new Date();
  const createdDate = new Date(created).getTime();
  const thirtyDaysAgo = today.setDate(today.getDate() - 30);

  return createdDate > thirtyDaysAgo;
}

interface WrapperLinkComponentProps {
  children: React.ReactNode;
  href?: string;
}

const WrapperLinkComponent = ({
  children,
  href
}: WrapperLinkComponentProps): React.ReactElement =>
  href ? <a href={href}>{children}</a> : <>{children}</>;

interface Props {
  name: string;
  icon: string;
  appID: string;
  developer?: string;
  created?: number;
  version?: string | number;
  osVersion?: string;
  isHorizontal?: boolean;
  className?: string;
  summary?: string;
  createButtonUrl?: string;
  type?: string;
  category?: string;
  isStaffPick?: boolean;
  href?: string;
  slug?: string;
}

export default function OneClickResource({
  name,
  icon,
  appID,
  developer,
  created,
  version,
  osVersion,
  isHorizontal,
  className = '',
  summary = '',
  slug = '',
  createButtonUrl,
  type,
  category,
  isStaffPick = false,
  href
}: Props): React.ReactElement {
  const { data: addOnsByAppData } = useQuery(GetAddonsByApp, {
    variables: {
      getAddonsByAppRequest: {
        app_uuid: appID
      }
    },
    skip: true,
    fetchPolicy: 'no-cache'
  });

  const hasAddons = _get(addOnsByAppData, 'GetAddonsByApp.addons');
  const isNew = isNewByDate(created);

  return (
    <OneClickResourcePaddingWrapper
      isHorizontal={isHorizontal}
      data-resource={slug}
    >
      <OneClickResourceContainer
        className={className}
        isHorizontal={isHorizontal}
      >
        <WrapperLinkComponent href={href}>
          {isNew && !isHorizontal && <Pill text={copy.new} />}
          <OneClickCardImageContainer isHorizontal={isHorizontal}>
            {icon && (
              <Image
                src={icon}
                alt={name}
                layout="fill"
                objectFit="contain"
                objectPosition="center"
              />
            )}
          </OneClickCardImageContainer>

          <OneClickResourceCopy isHorizontal={isHorizontal}>
            <OneClickResourceName
              title={name}
              isHorizontal={isHorizontal}
              lines={2}
              lineHeight={1.25}
            >
              {name}
            </OneClickResourceName>
            {developer && <OneClickDeveloper>by {developer}</OneClickDeveloper>}

            <OneClickResourceMeta isHorizontal={isHorizontal}>
              {isHorizontal ? (
                <HorizontalMetaDetails>
                  <p>
                    {version && (
                      <>
                        <strong>Version</strong> {version}
                      </>
                    )}
                  </p>
                  <p>
                    {isKubernetes(type) && !isHorizontal ? (
                      <strong>Kubernetes</strong>
                    ) : (
                      osVersion && (
                        <>
                          <strong>OS</strong> {osVersion}
                        </>
                      )
                    )}
                  </p>
                </HorizontalMetaDetails>
              ) : (
                <MetaDetails>
                  <p>{category && category}</p>
                  <p>
                    {getAppTypeText(type, slug) && !isHorizontal && (
                      <strong data-testid="app-type-text">
                        {getAppTypeText(type, slug)}
                      </strong>
                    )}
                  </p>
                </MetaDetails>
              )}

              {!isHorizontal && (
                <Summary>
                  <TruncatedText as="p" lines={2}>
                    {summary}
                  </TruncatedText>
                </Summary>
              )}

              {isHorizontal && (
                <>
                  {isKubernetes(type) && <DetailPill text={copy.kubernetes} />}
                  {isStaffPick && <DetailPill text={copy.staffPick} />}
                  {category && !isKubernetes(category.toLowerCase()) && (
                    <DetailPill text={category} />
                  )}
                  {isNew && <NewDetailPill text={copy.new} />}
                  {hasAddons && <DetailPill text={copy.licensedSoftware} />}
                </>
              )}
            </OneClickResourceMeta>
          </OneClickResourceCopy>
        </WrapperLinkComponent>
        {!isHorizontal && (
          <CardActionsWrapper>
            <SeeDetailsCardButton
              url={href}
              variation="secondary"
              compact
              fullWidth
            >
              {copy.seeDetails}
            </SeeDetailsCardButton>
            <Button
              url={createButtonUrl}
              className={PENDO_ID}
              data-tracking-id={
                isKubernetes(type) && 'doks-install-add-on-from-marketplace'
              }
              data-test-id={`${slug}-${type}-btn`}
              fullWidth
              compact
            >
              {getCreateButtonText[type]}
            </Button>
          </CardActionsWrapper>
        )}
      </OneClickResourceContainer>
    </OneClickResourcePaddingWrapper>
  );
}
